import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "gameplay"
    }}>{`Gameplay`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "quest"
    }}>{`Quest`}</h3>
    <p>{`A quest will require the player to deliver an order to complete. You need to produce items according to the given order.`}</p>
    <p><img alt="quest" src={require("./public/images/quest-1.png")} /></p>
    <ul>
      <li parentName="ul">{`Standard Quest`}</li>
    </ul>
    <p>{`The players who buy standard farmlands from the dashboard can accept standard quests from quest UI in the game.`}</p>
    <p><img alt="quest" src={require("./public/images/quest-2.png")} /></p>
    <ul>
      <li parentName="ul">{`VIP Quest`}</li>
    </ul>
    <p>{`The players who buy great farmlands from the dashboard can accept VIP quests from quest UI in the game.`}</p>
    <p><img alt="quest" src={require("./public/images/quest-3.png")} /></p>
    <ul>
      <li parentName="ul">{`Quest reward`}</li>
    </ul>
    <p>{`Quest reward depends on the type of the farmland. Great farmlands earn the players more reward ( CLC ) than standard farmlands.`}</p>
    <ul>
      <li parentName="ul">{`Quest Cooldown`}</li>
    </ul>
    <p>{`When the quest was completed or failed due to time out. Quest cooldown will start counting (cooldown duration depends on the character’s skills)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      